import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Playerzone from "./components/PlayerZone/playerzone";
import { ToastProvider } from 'react-toast-notifications';
import Secondscreen from "./components/Screens/screen2";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Universelpage from "./components/404";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <ToastProvider>
        <div className="App" id={load ? "no-scroll" : "scroll"}>
          <Navbar />
          <ScrollToTop />

          {/* Route for Home */}
          <Routes>
            <Route path="/" element={<Navigate to="/game/index.html" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/game" element={<Home />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/playerzone/:id" element={<Playerzone />} />
            <Route path="/gamedetails/:id" element={<Secondscreen />} />
            <Route path="*" element={<Universelpage />} />
          </Routes>

          {/* Footer outside the route to always display */}
          <Footer />
        </div>
      </ToastProvider>
    </Router>
  );
}

export default App;
